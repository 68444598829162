import { useMemo } from 'react';
import { RadioNetworkType, SortType, TranscriptQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';
import { queryParamsService } from '@/shared/services';
import { SharedTranscriptsProps } from '@/shared/types';
import useMapHistorySettings from './useMapHistorySettings';

enum RequestQueryParam {
  Search = 'search',
  Order = 'order',
  Location = 'location[]',
  Faction = 'faction[]',
  Category = 'category[]',
  FrequencyValue = 'frequencyValue[]',
  Source = 'source[]',
  Group = 'group[]',
  DateFrom = 'date_from',
  DateTo = 'date_to',
}

interface Params extends SharedTranscriptsProps {}

export const useTranscriptQueryParams = ({ networkId: radioNetworkId, networkType: radioNetworkType }: Params = {}) => {
  const [order] = useSearchParam<SortType>(TranscriptQueryParam.Order);
  const [search] = useSearchParam(TranscriptQueryParam.Search);
  const [location] = useSearchParam(TranscriptQueryParam.Location);
  const [faction] = useSearchParam(TranscriptQueryParam.Faction);
  const [category] = useSearchParam(TranscriptQueryParam.Category);
  const [frequency] = useSearchParam(TranscriptQueryParam.Frequency);
  const [source] = useSearchParam(TranscriptQueryParam.Source);
  const [group] = useSearchParam(TranscriptQueryParam.Group);
  const [dateRange] = useSearchParam(TranscriptQueryParam.DateRange);
  const history = useMapHistorySettings();

  const [dateFrom, dateTo] = useMemo(() => {
    if (history?.isEnabled && history?.dateRange) {
      return history.dateRange.map(String);
    }

    return queryParamsService.convertQueryParamToArray(dateRange);
  }, [history, dateRange]);

  const queryParams = new URLSearchParams([
    [RequestQueryParam.Order, order || SortType.Descending],
    ...(search ? [[RequestQueryParam.Search, search]] : []),
    ...queryParamsService.convertQueryParamToArray(location).map((item) => [RequestQueryParam.Location, item]),
    ...queryParamsService.convertQueryParamToArray(faction).map((item) => [RequestQueryParam.Faction, item]),
    ...queryParamsService.convertQueryParamToArray(category).map((item) => [RequestQueryParam.Category, item]),
    ...queryParamsService.convertQueryParamToArray(frequency).map((item) => [RequestQueryParam.FrequencyValue, item]),
    ...queryParamsService.convertQueryParamToArray(source).map((item) => [RequestQueryParam.Source, item]),
    ...queryParamsService.convertQueryParamToArray(group).map((item) => [RequestQueryParam.Group, item]),
    ...(dateFrom ? [[RequestQueryParam.DateFrom, dateFrom]] : []),
    ...(dateTo ? [[RequestQueryParam.DateTo, dateTo]] : []),
  ]).toString();

  const radioNetworkParams = useMemo(() => {
    const frequencyId = radioNetworkId && radioNetworkType === RadioNetworkType.FREQUENCY ? [radioNetworkId] : null;
    const networkId = radioNetworkId && radioNetworkType === RadioNetworkType.NETWORK ? [radioNetworkId] : null;

    return {
      frequency: frequencyId,
      network: networkId,
    };
  }, [radioNetworkId, radioNetworkType]);

  const queryParamsWithRadioNetworkId = queryParamsService.extendByExtra(
    { queryParams, extra: radioNetworkParams },
    { checkPagination: false }
  );

  return {
    order,
    search,
    location,
    faction,
    category,
    frequency,
    source,
    group,
    dateRange,
    queryParams: queryParamsWithRadioNetworkId,
  };
};
