import { z } from 'zod';
import { responseDataSchema } from '@/shared/validationSchemas';

const markerPropertiesSchema = z.object({
  type: z.enum(['point', 'location']),
  date: z.number(),
  transcriptId: z.string(),
  frequencyId: z.string().nullable(),
  locations: z.array(z.string()),
});

const heatmapPropertiesSchema = z.object({
  h3index: z.string(),
  pointCount: z.number(),
});

const geoJsonFeatureSchema = z.object({
  type: z.literal('Feature'),
  geometry: z.object({
    type: z.literal('Point'),
    coordinates: z.tuple([z.number(), z.number()]),
  }),
});

export const markerFeatureSchema = geoJsonFeatureSchema.merge(
  z.object({
    properties: markerPropertiesSchema,
  })
);

export const heatmapFeatureSchema = geoJsonFeatureSchema.merge(
  z.object({
    properties: heatmapPropertiesSchema,
  })
);

export const geoJsonFeatureCollectionSchema = z.object({
  type: z.literal('FeatureCollection'),
  features: z.array(markerFeatureSchema).or(z.array(heatmapFeatureSchema)),
});

export const getInterceptionsMapPointsSchema = responseDataSchema(geoJsonFeatureCollectionSchema);

export const getMapKMLDataSchema = responseDataSchema(z.string());
