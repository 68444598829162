import { DatePicker, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { COPIES } from '@/shared/constants';
import { DATE_RANGE_FORMAT, DATE_RANGE_FORMAT_SHORT, RANGE_PRESETS } from './constants';
import { useDateRangePicker } from './hooks';
import styles from './styles.module.css';
import { DateRangePickerProps, FormDateRangePickerProps } from './types';

export const DateRangePicker = ({
  format = DATE_RANGE_FORMAT,
  mobileFormat = DATE_RANGE_FORMAT_SHORT,
  presets = RANGE_PRESETS,
  value,
  onChange,
  ...restProps
}: DateRangePickerProps) => {
  const {
    isMobile,
    showTime,
    size,
    defaultPickerValue,
    mobilePopupContainerRef,
    getPopupContainer,
    handleChange,
    handleOk,
    handleDisabledDate,
    handleCalendarChange,
  } = useDateRangePicker({ onChange });

  return (
    <>
      <DatePicker.RangePicker
        {...restProps}
        size={size}
        className={styles.dateRangePicker}
        popupClassName={styles.dateRangePickerPopover}
        defaultPickerValue={!value ? defaultPickerValue : undefined}
        disabledDate={handleDisabledDate}
        presets={isMobile ? undefined : presets}
        format={isMobile ? mobileFormat : format}
        showTime={showTime}
        getPopupContainer={getPopupContainer}
        value={value}
        onChange={handleChange}
        onOk={handleOk}
        onCalendarChange={handleCalendarChange}
        allowClear={{ clearIcon: <CloseOutlined className={styles.clearIcon} /> }}
      />
      {isMobile && <div ref={mobilePopupContainerRef} />}
    </>
  );
};

export const FormDateRangePicker = ({ formItemProps, ...dateRangePickerProps }: FormDateRangePickerProps) => {
  const { label = COPIES.DATE, ...restFormItemProps } = formItemProps ?? {};

  return (
    <Form.Item
      label={label}
      {...restFormItemProps}
    >
      <DateRangePicker {...dateRangePickerProps} />
    </Form.Item>
  );
};
