import { MAP_SETTINGS_STORAGE_KEY } from '@/shared/constants';
import { useLocalStorageForLimitedDays, useParams } from '@/shared/hooks';
import { MapHistorySettingsLocalStorage, RadioNetworkRouteParams } from '@/shared/types';

const useMapHistorySettings = () => {
  const { radioNetworkId } = useParams<RadioNetworkRouteParams>();
  const { getStorageValue } = useLocalStorageForLimitedDays<MapHistorySettingsLocalStorage>(MAP_SETTINGS_STORAGE_KEY);
  const storageValue = getStorageValue() ?? {};
  const { history } = storageValue[radioNetworkId ?? 'all'] ?? {};

  return history;
};

export default useMapHistorySettings;
