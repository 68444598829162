import { FitBoundsOptions } from 'maplibre-gl';
import { LatLngCoordinates } from '@/shared/types';

export * from './colors';
export * from './expressions';

export const MAP_MIN_ZOOM = 2;
export const MAP_MAX_ZOOM = 19;

export const MAP_CLUSTER_RADIUS = 60;
export const MAP_CLUSTER_RADIUS_SCALE = 16; // is used internally by maplibre
export const MAP_CLUSTER_CASCADE_ZOOM = 12;

export const DEFAULT_BOX_SOUTH_WEST_POINT: LatLngCoordinates = { lat: 46.466525, lng: 31.456331 };
export const DEFAULT_BOX_NORTH_EAST_POINT: LatLngCoordinates = { lat: 50.392744, lng: 39.181469 };

export const FIT_BOUNDS_OPTIONS: FitBoundsOptions = {
  maxZoom: 11,
  padding: 60,
  animate: false,
};

export enum MapSource {
  Google = 'google',
  GoogleSatellite = 'google-satellite',
  GoogleLabels = 'google-labels',
  OpenStreet = 'openstreet',
  Markers = 'markers',
  ClusterPolygon = 'cluster-polygon',
  Kml = 'kml',
  Elevation = 'elevation',
  Carto = 'carto',
  CartoLight = 'carto-positron',
  CartoDark = 'carto-dark-matter',
  HexagonGrid = 'hexagon-grid',
  Hexagons = 'hexagons',
}

export enum MapLayer {
  Bg = 'background',
  CartoBg = 'carto-background',
  LabelsOnly = 'labels-only',
  Cluster = 'cluster-marker',
  ClusterInner = 'cluster-marker-inner',
  ClusterCount = 'cluster-marker-count',
  Point = 'point-marker',
  PointInner = 'point-marker-inner',
  PointCount = 'point-marker-count',
  ClusterPolygon = 'cluster-polygon',
  ClusterPolygonBorder = 'cluster-polygon-border',
  Kml = 'kml',
  Elevation = 'elevation',
  HexagonGrid = 'hexagon-grid',
  Hexagons = 'hexagons',
  Heatmap = 'heatmap',
}

export enum MarkerType {
  Cluster = 'cluster',
  Point = 'point',
}

export enum ClusterGroupedKey {
  Default = 'default',
  Frequency = 'frequencyId',
}

export const GROUP_NO_ID = 'no-id';

// All available fonts to use in map
// https://github.com/maptiler/carto-basemap-styles/tree/master/cartocss/web-styles/shared/fonts
export const SYMBOL_TEXT_FONTS = ['Open Sans Semibold'];
export const SYMBOL_TEXT_SIZE = 12;

export const SELECT_MARKER_TIMEOUT = 5000;
export const SETTLEMENT = 'Населений пункт';
export const POINT = 'Точка';

export const CENTERED_CLUSTER_CIRCLE_RADIUS = 25;
export const CLUSTER_CIRCLE_RADIUS = 20;

export const CENTERED_POINT_CIRCLE_RADIUS = 12.5;
export const POINT_CIRCLE_RADIUS = 10;

export const LOCKED_INTERACTION_TITLE = 'Вімкнено режим історії';
export const LOCKED_TRANSCRIPT_TRANSITION = 'Перехід на перехоплення заблоковано';
export const LOCKED_CLUSTER = 'Взаємодія з кластером заблокована';
