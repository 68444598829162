import { useEffect } from 'react';
import pick from 'lodash/pick';
import { ClusterGroupedKey, MapSource } from '@/entities/map/constants';
import { selectRadioNetworkMapById, addRadioNetworkMap, updateRadioNetworkMap } from '@/entities/map/slices';
import { RadioNetworkMap, MapSettingsLocalStorage } from '@/entities/map/types';
import { ALL_INTERCEPTIONS_PATH, MAP_SETTINGS_STORAGE_KEY, RADIO_NETWORKS_PATH } from '@/shared/constants';
import {
  useAppDispatch,
  useAppSelector,
  useLocalStorageForLimitedDays,
  useMatchRoutes,
  useParams,
} from '@/shared/hooks';
import { RadioNetworkRouteParams } from '@/shared/types';

const MAP_SETTINGS_SAVED_KEYS = ['tileSource', 'clusterGroupedKey', 'searchValue', 'searchCoordinates', 'history'];
const MAP_SETTINGS_ROUTES = [{ path: ALL_INTERCEPTIONS_PATH }, { path: RADIO_NETWORKS_PATH }];

const INITIAL_STATE: RadioNetworkMap = {
  radioNetworkId: '',
  tileSource: MapSource.Google,
  clickedMarkerTranscriptId: '',
  centerToFrequencyId: null,
  centerToCoordinates: null,
  centerToPopupData: null,
  clusterPoints: null,
  clusterPointsCoords: null,
  clusterGroupedKey: ClusterGroupedKey.Default,
  clusterGroupedValues: [],
  searchValue: '',
  searchCoordinates: null,
  history: null,
  bbox: null,
};

const useRadioNetworkMapStore = () => {
  const { getStorageValue, setStorageValue } =
    useLocalStorageForLimitedDays<MapSettingsLocalStorage>(MAP_SETTINGS_STORAGE_KEY);
  const storageValue = getStorageValue() ?? {};

  const { radioNetworkId = 'all' } = useParams<RadioNetworkRouteParams>();
  const radioNetworkMap = useAppSelector(selectRadioNetworkMapById(radioNetworkId));
  const isRouteMatched = useMatchRoutes(MAP_SETTINGS_ROUTES);
  const dispatch = useAppDispatch();

  const updateStore = (changes: Partial<RadioNetworkMap>) => {
    if (!isRouteMatched) return;

    const shouldSyncWithLocalStorage = Object.keys(changes).some((key) => MAP_SETTINGS_SAVED_KEYS.includes(key));

    dispatch(updateRadioNetworkMap({ radioNetworkId, changes }));

    if (radioNetworkMap && shouldSyncWithLocalStorage) {
      const oldFields = pick(radioNetworkMap, MAP_SETTINGS_SAVED_KEYS);
      const updatedFields = pick(changes, MAP_SETTINGS_SAVED_KEYS);
      const mapSettings = {
        ...oldFields,
        ...updatedFields,
      };

      setStorageValue({
        ...storageValue,
        [radioNetworkId]: mapSettings,
      });
    }
  };

  useEffect(() => {
    if (!isRouteMatched) return;

    const mapSettings = storageValue[radioNetworkId] ?? {};

    dispatch(
      addRadioNetworkMap({
        ...INITIAL_STATE,
        ...mapSettings,
        radioNetworkId,
      })
    );
  }, [radioNetworkId, dispatch]);

  return { ...(isRouteMatched ? radioNetworkMap || INITIAL_STATE : INITIAL_STATE), updateStore };
};

export default useRadioNetworkMapStore;
