import { useMemo } from 'react';
import { FoxRecordsQueryParam } from '@/shared/constants';
import { useSearchParam } from '@/shared/hooks';
import { queryParamsService, timeService } from '@/shared/services';
import { RangeValue } from '@/shared/types';

const useFiltersManager = () => {
  const [encryptionType, setEncryptionType] = useSearchParam(FoxRecordsQueryParam.EncryptionType);
  const [frequency, setFrequency] = useSearchParam(FoxRecordsQueryParam.Frequency);
  const [sourceAddress, setSourceAddress] = useSearchParam(FoxRecordsQueryParam.SourceAddress);
  const [groupAddress, setGroupAddress] = useSearchParam(FoxRecordsQueryParam.GroupAddress);
  const [dateRange, setDateRange] = useSearchParam(FoxRecordsQueryParam.DateRange);
  const [digitalSignal] = useSearchParam(FoxRecordsQueryParam.DigitalSignal);
  const [analogSignal] = useSearchParam(FoxRecordsQueryParam.AnalogSignal);
  const [decoded] = useSearchParam(FoxRecordsQueryParam.Decoded);
  const [notDecoded] = useSearchParam(FoxRecordsQueryParam.NotDecoded);

  const memoEncryptionType = useMemo(
    () => queryParamsService.convertQueryParamToArray(encryptionType),
    [encryptionType]
  );
  const memoFrequency = useMemo(() => queryParamsService.convertQueryParamToArray(frequency), [frequency]);
  const memoSourceAddress = useMemo(() => queryParamsService.convertQueryParamToArray(sourceAddress), [sourceAddress]);
  const memoGroupAddress = useMemo(() => queryParamsService.convertQueryParamToArray(groupAddress), [groupAddress]);
  const memoDateRange = useMemo(
    () => timeService.convertTimestampsToRangeValue(queryParamsService.convertQueryParamToArray(dateRange)),
    [dateRange]
  );

  const handleEncryptionTypeChange = (value: string[]) => {
    setEncryptionType(queryParamsService.convertArrayToQueryParam(value));
  };

  const handleFrequencyChange = (value: string[]) => {
    setFrequency(queryParamsService.convertArrayToQueryParam(value));
  };

  const handleSourceAddressChange = (value: string[]) => {
    setSourceAddress(queryParamsService.convertArrayToQueryParam(value));
  };

  const handleGroupAddressChange = (value: string[]) => {
    setGroupAddress(queryParamsService.convertArrayToQueryParam(value));
  };

  const handleDateRangeChange = (value: RangeValue) => {
    const range = timeService.convertRangeValueToTimestamps(value) ?? [];
    setDateRange(queryParamsService.convertArrayToQueryParam(range));
  };

  return {
    memoEncryptionType,
    memoFrequency,
    memoSourceAddress,
    memoGroupAddress,
    memoDateRange,
    digitalSignal,
    analogSignal,
    decoded,
    notDecoded,
    handleEncryptionTypeChange,
    handleFrequencyChange,
    handleSourceAddressChange,
    handleGroupAddressChange,
    handleDateRangeChange,
  };
};

export default useFiltersManager;
