import { Buffer } from 'buffer';
import {
  Centrifuge,
  TransportEndpoint,
  Options,
  ClientEvents,
  SubscriptionOptions,
  SubscriptionEvents,
  type Error,
} from 'centrifuge';

export class CentrifugeService {
  private centrifuge: Centrifuge | null = null;

  init({
    endpoint,
    options,
    onInitializationFail,
  }: {
    endpoint: string | TransportEndpoint[];
    options?: Partial<Options>;
    onInitializationFail: (error: unknown) => void;
  }) {
    try {
      this.centrifuge = new Centrifuge(endpoint, options);
    } catch (error) {
      onInitializationFail(error);
    }
  }

  connect() {
    this.centrifuge?.connect();
  }

  disconnect() {
    this.centrifuge?.disconnect();
  }

  addEventListeners(listeners: Partial<ClientEvents>) {
    Object.entries(listeners).forEach(([event, handler]) => {
      this.centrifuge?.on(event as keyof ClientEvents, handler);
    });
  }

  subscribeToChanel({
    options,
    listeners,
    onSubscriptionFail,
  }: {
    options: Pick<SubscriptionOptions, 'token'> & Partial<SubscriptionOptions>;
    listeners?: Partial<SubscriptionEvents>;
    onSubscriptionFail: (error: unknown) => void;
  }) {
    try {
      const { channel } = this.getParsedJwt(options.token);

      if (!channel) {
        throw new Error(`could not get channel from token ${options.token}`);
      }

      const sub = this.centrifuge?.newSubscription(channel, options);
      if (listeners) {
        Object.entries(listeners).forEach(([event, handler]) => {
          sub?.on(event as keyof SubscriptionEvents, handler);
        });
      }
      sub?.subscribe();

      return () => {
        sub?.unsubscribe();
        sub?.removeAllListeners();
      };
    } catch (error) {
      onSubscriptionFail(error);
    }
  }

  private getParsedJwt(token: string): { channel: string } {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
  }

  shouldCaptureException(error: Error) {
    // code 109 occurs after centrifuge token is expired
    // code 5 occurs after Strybo token is expired
    // code 2 occurs sometime when a fox device stops sending data
    return !(error.code === 109 || error.code === 5 || error.code === 2);
  }
}

export const centrifugeService = new CentrifugeService();
